<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:style="bgStyle(event)"
		class="col-12 p-3 store-free-virtual-product-banner">
		<div class="row container mx-auto p-0 h-100">
			<div
				:style="['md', 'lg', 'xl'].includes(windowWidth) ? 'max-width: 480px;' : ''"
				class="col-12 col-md-6 py-3 d-flex flex-column ml-auto mr-0">
				<div class="flex-grow-1">
					<h3
						:style="`color: ${bannerContent(event, 'titleColor')}`">
						{{ bannerContent(event, 'title') }}
					</h3>
					<p
						class="text-justify mt-4"
						:style="`color: ${bannerContent(event, 'descriptionColor')}`"
						v-html="bannerContent(event, 'description')" />
				</div>
				<div
					class="row mt-4">
					<div class="col align-self-center">
						<button
							style="font-weight: 600;"
							class="btn p-2 pointer custom-btn custom-primary-btn"
							:style="buttonClass(event)"
							@click="showModal">
							{{ translate('i_want_to_attend') }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-5 order-first order-sm-last">
				<lazy>
					<img
						:src="bannerContent(event, 'image')"
						:alt="bannerContent(event, 'alt')"
						class="img-fluid mx-auto"
						style="max-width: 275px;">
				</lazy>
			</div>
		</div>
		<custom-modal
			:show.sync="showTicketModal"
			:pre-title="!alreadyHasTicket ? translate('ticket_modal_pre_title') : ''"
			:close-text="translate('close')"
			ref-name="ticketModal"
			:title="!alreadyHasTicket ? translate('ticket_modal_title') : translate('reservation_complete')"
			:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'"
			background-color="rgb(0, 164, 255)">
			<template v-if="alreadyHasTicket">
				<div
					class="text-center px-3 pt-2"
					v-html="translate(event.code_name + '_registration_details')" />
				<br><br>
				<img
					:src="ticketUrl"
					alt="Ticket Image"
					width="100%"
					style="max-width: 100%;">
			</template>
			<template v-else>
				<div
					class="text-center px-3 pt-2"
					v-html="translate(event.code_name + '_details')" />
			</template>

			<template #footer>
				<div>
					<div
						v-if="alreadyHasTicket"
						class="d-flex justify-content-center text-center mt-1">
						<button
							class="btn text-white"
							style="background-color: rgb(0, 164, 255) !important;"
							@click="hideModal()">
							{{ translate('continue') }}
						</button>
					</div>
					<div v-else>
						<div class="d-flex justify-content-center text-center">
							<button
								class="btn bg-primary py-2 px-5 pointer font-weight-bold"
								style="min-width: 25%; background-color: rgb(0, 164, 255) !important;"
								:disabled="submitting"
								@click="createNewTicket()">
								<span class="text-white">
									<i
										v-if="submitting"
										class="fas fa-spinner fa-spin mr-1" />
									{{ translate('ticket_modal_save') }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</template>
		</custom-modal>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { FreeEvents, Products, Store } from '@/translations';
import Lazy from '@/components/Lazy';
import CustomModal from '@/components/CustomModal/index.vue';
import ProductsInfo from '@/util/Products';

export default {
	name: 'StoreFreeVirtualEventBanner',
	components: { CustomModal, Lazy },
	mixins: [WindowSizes],
	messages: [Products, Store, FreeEvents],
	props: {
		backgroundStyle: {
			type: String,
			default: '',
		},
		event: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			products: new ProductsInfo(),
			showTicketModal: false,
			alreadyHasTicket: false,
			submitting: false,
			ticketUrl: '',
		};
	},
	methods: {
		bgStyle(product) {
			let style = '';
			if (this.showBackground(product)) {
				style = `--store-free-virtual-event-banner-background:url(${this.bannerContent(product, 'backgroundImg')});`;
			}
			return `${style} ${this.bannerContent(product, 'backgroundStyle')}`;
		},
		bannerContent(product, data) {
			try {
				return product.bannerContent[data];
			} catch (e) {
				return '';
			}
		},
		showBackground(product) {
			if (typeof product.bannerContent.showBackground === 'undefined') {
				return true;
			}
			return product.bannerContent.showBackground;
		},
		buttonClass(product) {
			return `color: ${this.bannerContent(product, 'buttonTextColor')}; background-color: ${this.bannerContent(product, 'buttonBackgroundColor')}`;
		},
		showModal() {
			this.showTicketModal = true;
		},
		hideModal() {
			this.showTicketModal = false;
		},
		createNewTicket() {
			this.submitting = true;
			this.products.generateFreeVirtualEventTicket({ event_code_name: this.event.code_name }).then((response) => {
				// eslint-disable-next-line camelcase
				const { ticket_url: ticketUrl } = response.response;
				this.ticketUrl = ticketUrl;
				this.alreadyHasTicket = true;
			}).finally(() => {
				this.submitting = false;
			});
		},
	},
};
</script>

<style scoped>
.store-free-virtual-product-banner {
	background: var(--store-free-virtual-event-banner-background);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
</style>
